import Card from "../../basic/app-dashboard/Card";

function CardSsjAN() {
    return(
        <Card
            href="/ssj-an"
            image="/dist/img/scott-graham-OQMZwNd3ThU-unsplash.jpg"
            title="Solicitação de Serviço Jurídico (SSJ) AN"
            description="Realize o envio dos serviços jurídicos internamente"
        />
    );
}

export default CardSsjAN;
