import AppHeader from '../../../basic/app-header/AppHeader';
import AdmMenu from '../adm-menu/AdmMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import api from '../../../../services/axios';
import { getAxios, postAxios } from '../../../../hooks/useAxios';
import { Modal } from 'react-bootstrap';
import OverviewReceipt from '../adm-dashboard/OverviewReceipt';
import CardDeleteClient from './CardDeleteClient';

function ViewClients() {
    
    // * Variáveis de estado
    // propriedades dos clientes
    const [clients, setClients] = useState(null);
    // auxiliar para o funcionamento do modal de visualizacao
    const [showModal, setShowModal] = useState(false);
    // cliente selecionado para a visualizacao
    const [viewClient, setViewClient] = useState({});
    // produtos do cliente a ser visualizado
    const [viewClientProducts, setViewClientProducts] = useState([])

    // * Funcoes para criacao do card de confirmacao de exclusao
    const [showCardDelete, setShowCardDelete] = useState(false);
    
    const handleCloseCardDelete = () => {
        setShowCardDelete(false);
    };
    
      const handleShowMCardDelete = () => {
        setShowCardDelete(true);
    };

    

    // * Busca dos dados
    // requisicao para os clientes da tabela
    useEffect(() => {
        const getClients = async () => {
            postAxios('/api/load/clients')
                .then((resp) => {
                    if(resp.data.authenticate === true) {
                        setClients(resp.data.clients);
                    }
                    else {
                        window.location.href = "/bad/request";
                    }
                })
                .catch((error) => {
                    window.location.href = "/bad/request";
                    console.error('Erro na solicitação POST:', error);
                })
        };
    
        getClients();
        
    }, []);
    
    if(clients == null || clients === undefined) {
        return(
            <>
            <AppHeader/>
            <AdmMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter/>
            <AppSetting/>
            </>
        ); 
    }


    // requisicao para um único cliente
    async function getOneClient(id) {
        try {
            const response = await api.post(`/api/load/client/view/${id}`);
            console.log(response)
            if (response.data.authenticate === true) {
                setViewClientProducts(response.data.productSelected);
                
            } else {
                window.location.href = "/bad/request";
                // console.log('else')
            }
        } catch (error) {
            console.error(error);
            // console.log('catch')
            window.location.href = "/bad/request";
        }
    }

    viewClientProducts.map(resp => (
        resp.label = resp.name
    ))

    async function deleteClient(id) {
        try {
            const response = await api.post(`/api/delete/client/${id}`);   
            if (response.data.authenticate === true) {
                window.location.reload();
            } else {
                window.location.href = "/bad/request";
            }
        } catch (error) {
            console.error(error);
            window.location.href = "/bad/request";
        }
    }

    console.log(viewClientProducts)
    // * Funções de formatação
    // Converte um valor float em uma representação monetária no formato "R$ 000.000,00"
    function formatFloatAsMoney(value) {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) {
            return "R$ 0.00";
        }

        const formattedValue = floatValue.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL"
        });

        return formattedValue;
    }

    // * Funções para modal de visualização do cliente

    const handleCloseModal = () => {
        setShowModal(false);
    };
    
    const handleShowModal = () => {
        setShowModal(true);
    };
    
    async function searchClient(id) {
        console.log('1')
        let left = 0;
        let right = clients.length - 1;
        
        while(left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midValue = clients[mid].id;
            
            if(midValue === id) {
                setViewClient(clients[mid])
                console.log(viewClient);
                await getOneClient(clients[mid].id);
                break;
            }
            else if(midValue < id) {
                left = mid + 1;
            }
            else {
                right = mid - 1;
            }
        }
    }

    function returnClient() {
        return(
            <>
            <div className="row">
                <div className='col-6'>
                    <div class="form-group">
                        <label for="name">Razão Social:</label>
                        <input type="text" name="name" id="name" class="form-control" value={viewClient.name || ''} disabled={true}/>
                    </div>
                </div>
                <div className='col-6'>
                    <div class="form-group">
                        <label for="cpfCnpj">CPF/CNPJ:</label>
                        <input type="text" name="cpfCnpj" id="cpfCnpj" class="form-control" value={viewClient.cpfCnpj || ''} disabled={true}/>
                    </div>
                </div>
            </div>
            <div className='row form-group'>
                <div className='col-4'>
                    <div class="form-group">
                        <label for="email">E-mail:</label>
                        <input type="email" name="email" id="email" class="form-control" value={viewClient.email || ''} disabled={true}/>
                    </div>
                </div>
                <div className='col-4'>
                    <div class="form-group">
                        <label for="contactOne">Contato 1:</label>
                        <input type="text" name="contactOne" id="contactOne" class="form-control" value={viewClient.contactOne || ''} disabled={true}/>
                    </div>
                </div>
                <div className='col-4'>
                    <div class="form-group">
                        <label for="contactTwo">Contato 2:</label>
                        <input type="text" name="contactTwo" id="contactTwo" class="form-control" value={viewClient.contactTwo || ''} disabled={true}/>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div class="form-group">
                        <label for="numberUsers">Quantidade de usuários:</label>
                        <input type="number" name="numberUsers" id="numberUsers" class="form-control" value={viewClient.numberUsers || ''} disabled={true}/>
                    </div>
                </div>
                <div className='col-6'>
                    <div class="form-group" >
                        <label for="products">Produto(s):</label>
                        <Select
                            options={viewClientProducts}
                            isMulti
                            placeholder=''
                            id="products"
                            value={viewClientProducts}
                            isDisabled={true}
                        />
                    </div>
                </div>
                {/* <div className="col-4">
                    <div className="form-group custom-center">
                        <OverviewReceipt
                            sx={{ borderRadius: '15px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)' }}
                            width={3}
                            height={1}
                            fontSize={'h5'}
                            positive
                            value={formatFloatAsMoney(viewClient.totalValue) || ''}    
                        />
                    </div>
                </div> */}
	</div>
	<div className='row'>
                <div className='col-4'>
                    <div class="form-group">
                        <label for='bi' className='required'>Link para integração do BI:</label>
                        <input type="text" className='form-control' name='bi' id='bi' value={viewClient.bi || ''} disabled={true}/>
                    </div>
                </div>
                <div className='col-4'>
                    <div class="form-group">
                        <label for='driveTrabalhista' className='required'>Link para pasta no drive trabalhista:</label>
                        <input type="text" className='form-control' name='driveTrabalhista' id='driveTrabalhista' value={viewClient.driveTrabalhista || ''} disabled={true}/>
                    </div>
                </div>
                <div className='col-4'>
                    <div class="form-group">
                        <label for='driveEmpresarial' className='required'>Link para pasta no drive empresarial:</label>
                        <input type="text" className='form-control' name='driveEmpresarial' id='driveEmpresarial' value={viewClient.driveEmpresarial || ''} disabled={true}/>
                    </div>
                </div>
            </div>
            </>
        );
    }
    
    function createModal() {
        return (
  
        <Modal show={showModal} onHide={handleCloseModal} size="xl">
           <Modal.Header className='bg-gray center-element'>
            <Modal.Title>Visualizando o cliente: {viewClient != null ? viewClient.name : null}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {returnClient()}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-gray" onClick={handleCloseModal}>Voltar</button>
          </Modal.Footer>
          </Modal>
        )
        
    }

    // // * Spinner
    // if(clients === null) {
    //     return(
    //         <>
    //         <AppHeader/>
    //         <AdmMenu/>
    //         <div className='content-wrapper'>
    //             <div className='card-body'>
    //                 <div style={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     alignItems: "center",
    //                     height: "100vh"
    //                 }}>
    //                     <div class="spinner-border" role="status">
    //                     <span class="sr-only">Loading...</span>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //         <AppFooter/>
    //         <AppSetting/>
    //         </>
    //     ); 
    // }

    // * Conteúdo da página
    return(
        <>
        <AppHeader/>
        <AdmMenu/>
        <div className="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header" style={{marginBottom: -5}}>
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6"/>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/adm">Portal Administrador</a></li>
                                <li class="breadcrumb-item active">Clientes</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- Main content --> */}
            <section class="content">
            <div class="container-fluid">
                <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" style={{fontWeight: 600}}>Clientes</h3>
                    </div>
                    <div class="card-header">
                        <a href="/adm/create/client"><button class="btn btn-sm btn-success">Novo cliente</button></a>
                    </div>
                    {/* <!-- /.card-header --> */}
                    <div class="card-body">
                        <table id="example1" class="table table-bordered table-striped">
                        <thead>
                        <tr>
                            <th>Razão Social</th>
                            <th>Email</th>
                            <th>CPF/CNPJ</th>
                            <th>Usuários ativos</th>
                            {/* <th>Valor total</th> */}
                            <th className='coluna-visualizar2'>Ações</th>
                        </tr>
                        </thead>
                        <tbody id="tabela-corpo">
                            {
                                clients && clients.map(resp=> (
                                    <tr>
                                        <td>{resp.name}</td>
                                        <td>{resp.email}</td>
                                        <td>{resp.cpfCnpj}</td>
                                        <td>{resp.numberUsers}</td>
                                        {/* <td>{formatFloatAsMoney(resp.totalValue)}</td> */}
                                        <td classList="action-column" className='center-element coluna-visualizar'>
                                            <button className='btn btn-gray eye-animation' style={{marginRight: 5}} onClick={()=>{searchClient(resp.id); handleShowModal()}}><i class='fa fa-eye icon-white'></i></button>
                                            <a href={`/adm/edit/client/${resp.id}`}><button className='btn btn-primary pen-animation'><i class='fa fa-pen icon-white'></i></button></a>
                                            <button className='btn btn-danger trash-animation' style={{marginLeft: 5}} onClick={()=>{searchClient(resp.id); handleShowMCardDelete()}}><i class='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        </table>
                        <div className='row'>
                            <div class="col-12 text-center">
                                {showModal ? createModal() : <></>}
                            </div>
                        </div>
                        <CardDeleteClient
                            showCard={showCardDelete}
                            handleCloseCard={handleCloseCardDelete}
                            message={'Essa ação irá excluir os dados do cliente. Tem certeza de que deseja fazer isso?'}
                            elementId={viewClient.id}
                        />
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </div>
        <AppFooter/>
        <AppSetting/>
        </>
    )

}

export default ViewClients;
