import AppHeader from '../../../basic/app-header/AppHeader';
import AdmMenu from '../adm-menu/AdmMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import OverviewReceipt from '../adm-dashboard/OverviewReceipt';
import { Box, Container, Unstable_Grid2 as Grid } from '@mui/material';
import { postAxios } from '../../../../hooks/useAxios';
import { useParams } from 'react-router-dom';
import { formatCpfCnpj } from '../../../../utils/formatCpfCnpj';
import { formatPhone } from '../../../../utils/formatPhone';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { formatNumeric } from '../../../../utils/formatNumeric';


function EditClient() {
    
    // * Variaveis de estado

    // id do cliente, obtido pela url
    const {id} = useParams();
    // propriedades dos clientes
    const [client, setClient] = useState(
        {
            name: null,
            email: null,
            cpfCnpj: null,
            contactOne: null,
            contactTwo: null,
            numberUsers: null,
            products: null,
            totalValue: null,
        }
        );
    const options = [
        { value: 'SSJ-Cliente', name: 'SSJ-Cliente', label: 'SSJ-Cliente'},
        { value: 'BI', name: 'BI', label: 'BI'},
        { value: 'Portal-Pagamentos' , name: 'Portal-Pagamentos', label: 'Portal-Pagamentos' },
        { value: 'SSJ-ADM', name: 'SSJ-ADM', label: 'SSJ-ADM' },
        { value: 'Holding', name: 'Holding', label: 'Holding'},
	{ value: 'Analysis', name: 'Analysis', label: 'Analysis'}
    ];
    const [products, setProducts] = useState([{name: null, field: null, description: null, value: null}]);
    const [companiesAdded, setCompaniesAdded] = useState([]);
    //const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
        
        
    // * Requisição para API

    // carregar os valore do cliente
    useEffect(() => {
        const getClient = async () => {
            try {
                const response = await postAxios(`/api/load/client/view/${id}`);
                if (response.data.authenticate === true) {
                    setClient(response.data.client);
                    setProducts(response.data.products);
                    setSelectedComponents(response.data.productSelected);
                    setCompaniesAdded(response.data.companies) // pegar isso no bd
			console.log(response.data);
                } else {
                    console.log(response)
                    alert("caiu no erro do status")
                    window.location.href = "/bad/request";
                }
            } catch (error) {
                console.log(error)
                alert("caiu no erro do try-catch")
                window.location.href = "/bad/request";
            }
        }

        getClient();
        
        
    }, [id]);
	console.log(client);
	console.log(products);
	console.log(selectedComponents);

    selectedComponents.map(resp => (
        resp.label = resp.name,
        resp.value = resp.name
    ))
    
    const handleSelectionChange = (selectedOptions) => {
        setSelectedComponents(selectedOptions);
    };

    // atualizar o valor do cliente
    function updateClient(){
        if (isProcessing) return; // Impede cliques adicionais enquanto já está em andamento

        setIsProcessing(true);
        const corporate = document.querySelector('#name').value;
        const email = document.querySelector('#email').value;
        const cpfCnpj = document.querySelector('#cpfCnpj').value;
        const contactOne = document.querySelector('#contactOne').value;
        const contactTwo = document.querySelector('#contactTwo').value;
        const numberUsers = document.querySelector('#numberUsers').value;
        const bi = document.querySelector('#bi').value;
        const driveTrabalhista = document.querySelector('#driveTrabalhista').value;
        const driveEmpresarial = document.querySelector('#driveEmpresarial').value;
        const products = selectedComponents;
        const companies = companiesAdded;

        console.log(selectedComponents);
        
        postAxios(`/api/update/client/${id}`, {corporate, email, cpfCnpj, contactOne, contactTwo, numberUsers, products, bi, driveTrabalhista, driveEmpresarial, companies})
            .then((resp)=>{
                if(resp.data.errors){
                    for(let i=0; i<resp.data.errors.length; i++){
                        let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`);
                        console.log(resp.data.errors[i].elemento)
                        console.log(elemento)
                        if(resp.data.errors[i].elemento !== 'totalValue'){
                            if(resp.data.errors[i].elemento === 'products') {
                                elemento.classList.add('erro-select');
                                const placeholder = elemento.querySelector('#react-select-3-placeholder');
                                placeholder.innerHTML = "Preencha esse campo";
                            }
                            else {
                                elemento.placeholder = "Preencha esse campo";
                                elemento.classList.add('erro');
                            }
                        }
                    }
                }
                window.location = `/adm/clients`
            })
            .catch((error) => {
                window.location.href = "/bad/request";
                console.error('Erro na solicitação POST:', error);
            })
            .finally(() => {
                setIsProcessing(false); // Habilita o botão novamente após a conclusão ou erro
            })
    }

    // função para permitir que os campos com valores pré-carregados possam ser alterados
    function loadForm(event){
        const { name, value } = event.target;
        setClient(prevState => ({
          ...prevState,
          [name]: value
        }));  
    }

    function loadFormSelect(selectedOptions) {
        // console.log(products)
        setSelectedComponents(selectedOptions);
        console.log(selectedComponents)
    }

    const handleCompanies = () => {
        const companyName = document.querySelector('#company').value.trim(); // Trim remove espaços em branco
    
        if (!companyName) {
            // Se o campo estiver vazio, exiba um alerta ou ignore a adição
            alert('Por favor, insira o nome de uma empresa.');
            return; // Retorna para impedir a adição de uma empresa vazia
        }
    
        // Crie o novo item com o nome da empresa
        const newItem = { id: companiesAdded.length + 1, cliente: companyName };
    
        // Adicione o novo item à lista de empresas adicionadas
        setCompaniesAdded((prevItems) => [...prevItems, newItem]);
    
        // Opcional: Limpe o campo após adicionar a empresa
        document.querySelector('#company').value = '';
    };
  
    const deleteCompany = (id) => {
        // Filtrar os itens com base no id para remover o item desejado
        const updatedItems = companiesAdded.filter((item) => item.id !== id);
        setCompaniesAdded(updatedItems);
    };

    return (
        <>
        <AppHeader />
        <AdmMenu />
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    <h1>Editor de cliente</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/adm">Início</a></li>
                        <li className="breadcrumb-item"><a href="/adm/clients">Clientes</a></li>
                        <li className="breadcrumb-item active">Editar cliente</li>
                    </ol>
                    </div>
                </div>
                </div>
            </section>

            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="card-header center-element bg-gray">
                            <h3 className="card-title" style={{fontWeight: 600}}>{`Editar cliente: ${client.name}`}</h3>
                        </div>
                        <div className="card-body">  
                            <div className="row">
                                <div className='col-6'>
                                    <div class="form-group">
                                        <label class="required" for="name">Razão Social:</label>
                                        <input type="text" name="name" id="name" class="form-control" value={client.name || ''} onChange={loadForm}/>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div class="form-group">
                                        <label class="required" for="cpfCnpj">CPF/CNPJ:</label>
                                        <input type="text" name="cpfCnpj" id="cpfCnpj" class="form-control" value={client.cpfCnpj || ''} onChange={loadForm} onInput={formatCpfCnpj}/>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-group'>
                                <div className='col-4'>
                                    <div className="form-group">
                                        <label className="required" htmlFor="email">E-mail:</label>
                                        <input type="email" name="email" id="email" className="form-control" value={client.email || ''} onChange={loadForm}/>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-group">
                                        <label htmlFor="contactOne">Contato 1:</label>
                                        <input type="text" name="contactOne" id="contactOne" className="form-control" value={client.contactOne || ''} onChange={loadForm} onInput={formatPhone}/>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-group">
                                        <label htmlFor="contactTwo">Contato 2:</label>
                                        <input type="text" name="contactTwo" id="contactTwo" className="form-control" value={client.contactTwo || ''} onChange={loadForm} onInput={formatPhone}/>
                                    </div>
                                </div>
                            </div>
                            <div className='input-grid-client'>
                                <div className='c0'>
                                    <div class="form-group" >
                                        <label class="required" for="products">Produto(s):</label>
                                        {/* <Select
                                            options={options}
                                            isMulti={true}
                                            placeholder=''
                                            id="products"
                                            onChange={(selectedOptions) => {
                                                // Atualize o estado dos componentes selecionados para conter apenas labels
                                                const selectedLabels = selectedOptions.map((option) => option.label);
                                                setSelectedComponents(selectedLabels);
                                                console.log(components);
                                                setOptions(components2);
                                            }}
                                            isClearable={true}
                                        /> */}
                                       <Select
                                            options={options}
                                            isMulti
                                            placeholder=''
                                            id="products"
                                            value={selectedComponents}
                                            onChange={handleSelectionChange}
                                        />

                                    </div>
                                </div>
                                <div className='c1'>
                                    <div class="form-group">
                                        <label class="required" for="numberUsers">Quantidade de usuários:</label>
                                        <input type="number" name="numberUsers" id="numberUsers" class="form-control" onInput={formatNumeric} value={client.numberUsers || ''} onChange={loadForm}/>
                                    </div>
                                </div>
                                {/* <div className="col-4">
                                    <div className="form-group custom-center">
                                        <OverviewReceipt
                                        sx={{ borderRadius: '15px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)' }}
                                        difference={4}
                                        width={4}
                                        heigth={1}
                                        fontSize={'h4'}
                                        positive
                                        value={formatFloatToMoney(totalValue(selectedComponents))}
                                        />
                                    </div>
                                </div> */}
                                <div className='c2 form-group' style={{marginLeft: 5}}>
                                    <div className='row'>
                                        <label className='required' htmlFor='company'>Empresa:</label>
                                    </div>
                                    <div className='row'>
                                        <div style={{width: '70%'}}>
                                            <input type="text" name="company" id="company" class="form-control"/>
                                        </div>
                                        <div style={{width: '25%', marginLeft: 5}}>
                                        <button class='btn btn-gray btn-block add' onClick={()=>{handleCompanies()}}><i class="fas fa-plus"/></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='c6' style={{marginTop: -15}}>
                                    <div className='row' style={{marginLeft: 5}}>
                                       <h5 className='companies-added'>Empresas adicionadas:</h5>
                                    </div>
                                    <div className='companies-container' style={{marginLeft: 5}}>
                                    <ul>
                                        {companiesAdded && companiesAdded.map((option) => (
                                        <>
                                        <div className='row company-item'>
                                            <p>{option.cliente}</p>
                                            <div style={{marginLeft: 15, marginTop: -12}}>
                                                <IconButton
                                                    color="error"
                                                    aria-label="remove"
                                                    onClick={()=>{deleteCompany(option.id)}}
                                                    className="remove-button"
                                                    >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <p className='separator' style={{marginTop: -15}}/>
                                        </>
                                        ))}
                                    </ul>
                                    </div>
                                </div>
                                <div className='c3'>
                                    <div class="form-group">
                                        <label for='bi' className='required'>Link para integração do BI:</label>
                                        <input type="text" className='form-control' name='bi' id='bi' value={client.bi || ''} onChange={loadForm}/>
                                    </div>
                                </div>
                                <div className='c4'>
                                    <div className='form-group'>
                                        <label for="driveTrabalhista" className='required'>Link para pasta trabalhista no drive:</label>
                                        <input type='text' className='form-control' name='driveTrabalhista' id='driveTrabalhista' value={client.driveTrabalhista || ''} onChange={loadForm}/>
                                    </div>
                                </div>
                                <div className='c5'>
                                    <div className='form-group'>
                                        <label for="driveEmpresarial" className='required'>Link para pasta empresarial no drive:</label>
                                        <input type='text' className='form-control' name='driveEmpresarial' id='driveEmpresarial' value={client.driveEmpresarial || ''} onChange={loadForm}/>
                                    </div>
                                </div>                   
                            </div>
                            <p  className="separator" style={{marginTop: 30}}/>
                            <div className="col-12 center-element">
                                <a href="/adm/clients"><button type="button" className="cancel btn btn-gray">Cancelar</button></a>
                                <button type="submit" className="enviar btn btn-gray" style={{marginLeft: 5}} onClick={updateClient}>
                                    {isProcessing ? 'Aguarde...' : 'Enviar'}
                                </button>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        {/* /.content */}
        </div>
        <AppFooter />
        <AppSetting />
        </>  
    );
}

export default EditClient;
