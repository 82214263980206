import { Link } from 'react-router-dom';

function ItemMenuSsjAN() {
    return(
        <>
        <li className="nav-item has-treeview menu-open">
            <Link className="nav-link">
                <i className="nav-icon fa fa-file"></i>
                <p>
                    SSJ AN
                    <i className="right fas fa-angle-left"></i>
                </p>
            </Link>
            <ul className="nav nav-treeview">
            <li className="nav-item subitem">
                <Link to="/ssj-an" className="nav-link">
                <i className="fas fa-file-alt nav-icon"></i>
                <p>Solicitação do serviço</p>
                </Link>
            </li>
            <li className="nav-item subitem">
                <Link to="/ssj/status" className="nav-link">
                <i className="fa fa-history nav-icon"></i>
                <p>Status de solicitações</p>
                </Link>
            </li>
            </ul>
        </li>
        </>
    );
}

export default ItemMenuSsjAN;
