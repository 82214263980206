import api from "../services/axios";

function getAxios(url, params = {}) {
	return api({
	  url: url, // a baseURL será adicionada automaticamente
	  method: 'GET',
	  params: params,
	})
	  .then((response) => response.data)
	  .catch((error) => {
		throw error.response?.data || error.message;
	  });
  }
  
  function postAxios(url, data, id = null, config = {}) {
	const fullUrl = id ? `${url}/${id}` : url;
  
	return api
	  .post(fullUrl, data, config) // usando a instância `api`
	  .then((response) => response)
	  .catch((error) => {
		throw error;
	  })
	  .finally(() => {
		// código que deseja executar após a requisição,
		// independentemente de ela ter sido bem-sucedida ou ter falhado.
	  });
  }
  
  export { getAxios, postAxios };