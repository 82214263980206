import './AdmLogin.css';
import React, {useEffect, useState} from 'react';
import api from '../../../../services/axios';
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


function AdmLogin() {
    const [blockedTime, setBlockedTime] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false)
    const [showPassword, setShowPassword] = useState(false);



    // * Funções para o relógio
    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
        
    function Relogio(props) {
        const dataExp = new Date(localStorage.getItem('tempo'))
        const dataAtual = new Date()

        if(props.blockedTime == 0){
            if(dataExp > dataAtual){
            let conta = Math.ceil((dataExp - dataAtual) / 1000);
            setBlockedTime(conta)
            setIsDisabled(true)
            } else {
            setIsDisabled(false)  
            }
        }

        if(props.blockedTime > 0){
            //setBlockedTime(localStorage.getItem("tempo"))
            return (
            <>
            <div id="relogio" className='row'>
                <div className='clock-adm'></div>
            </div>
            <div className='time-adm'><a style={{marginTop: -50}}>{formatTime(props.blockedTime)}</a></div>
            </>
            );
        }
    }

    useEffect(() => {
    let interval = null;
    if (blockedTime > 0) {
        interval = setInterval(() => {
        setBlockedTime((prevTime) => prevTime - 1);
        }, 1000);
    }
    return () => clearInterval(interval);
    }, [blockedTime]);  

    // * Funções de validação para o front

    function validacao(caso1, caso2, caso3){
        const email = document.querySelector('#email')
        const senha = document.querySelector('#senha')
        let valorEmail = email.value
        let valorSenha = senha.value
        const emailLabel = document.querySelector('#tag-email')
        const senhaLabel = document.querySelector('#tag-senha')

        email.classList.add('invalid-border-adm')
        senha.classList.add('invalid-border-adm')
        if (caso1 != null){
            emailLabel.textContent = "Digite o email"
            email.classList.add('blank-adm')

        } else if (caso3){
            emailLabel.textContent = "Dados incorretos"
            email.classList.add('invalid-adm')  
        }
        if(caso2 != null){
            senhaLabel.textContent = "Digite a senha"
            senha.classList.add('blank-adm')   
            
        } else if (caso3){
            senhaLabel.textContent = "Dados incorretos"
            senha.classList.add('invalid-adm') 
        } 
        
        

        email.addEventListener("click", ()=>{
            email.classList.remove('invalid-adm')
            email.classList.remove('blank-adm')
            email.classList.remove('invalid-border-adm')
            email.classList.remove('muitas-tentativas-adm')
            emailLabel.textContent = "Email"
        })
        senha.addEventListener("click", ()=>{
            senha.classList.remove('invalid-adm')
            senha.classList.remove('blank-adm')
            senha.classList.remove('invalid-border-adm')
            senha.classList.remove('aguarde-adm')
            senhaLabel.textContent = "Senha"
        })  
    }

    function focusEmail(){
        let email = document.querySelector('#email').value
        let emailCampo = document.querySelector('#email')
        if(email != '' || email != null){
            emailCampo.classList.add('foco-adm')
            if(email == ''){
                emailCampo.classList.remove('foco-adm')
            }
        }
    }

    function focusSenha(){
        let senha = document.querySelector('#senha').value
        let senhaCampo = document.querySelector('#senha')
        if(senha != '' || senha != null){
            senhaCampo.classList.add('foco-adm')
            if(senha == ''){
                senhaCampo.classList.remove('foco-adm')
            }
        }
    }

    function muitasTentativas(){
        const email = document.querySelector('#email')
        const senha = document.querySelector('#senha')
        const emailLabel = document.querySelector('#tag-email')
        const senhaLabel = document.querySelector('#tag-senha')
        
        emailLabel.textContent = "Muitas tentativas inválidas"
        senhaLabel.textContent = "Aguarde 5 minutos"

        email.classList.add('muitas-tentativas-adm');
        senha.classList.add('aguarde-adm');
        
    }

    function removeInvalidBorder(){
        const email = document.querySelector('#email')
        const senha = document.querySelector('#senha')
        email.classList.remove('invalid-border-adm')
        senha.classList.remove('invalid-border-adm')

        // remove a classe de muitas tentativas e aguarde-adm para evitar bugs
        email.classList.remove('muitas-tentativas-adm')
        senha.classList.remove('aguarde-adm')
        
    }

    // * Função de autentificação

    async function sign(){
        let msg = "entra funcao"
        try {
            msg = 'entra requisicao'
            const email = document.querySelector('#email').value;
            const senha = document.querySelector('#senha').value;

            await api.post(`/api/sign/an`, {email, senha}, {withCredentials: true}).then((response)=>{
                if(response.data.status === 200){
                    window.location.href = "/adm";
                } else if(response.data.status === 400) {
                    if(response.data.msg1 ||  response.data.msg2 || response.data.msg === "invalido"){
                        setTimeout(()=>{
                            validacao(response.data.msg1, response.data.msg2, response.data.msg);
                        }, 50) //Pequeno delay de criação apenas para deixar mais intuitivo
                        
                    } else if (response.data.msg == "muitas tentativas") {
                        muitasTentativas();
                        // alert("Muitas tentativas inválidas! Tente novamente em 1 minuto.");
                        setBlockedTime(response.data.time);
                        localStorage.setItem("tempo", response.data.date)
                        setIsDisabled(true);
                    }
                }
            })
        } catch(error) {
            console.error(error);
            window.location.href = "/bad/request";
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    // Adicionando evento de tecla Enter
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Enter") {
                sign();
                removeInvalidBorder();
            }
        };
        document.addEventListener("keypress", handleKeyPress);
        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, []);

    return (
        <div className="hold-transition login-page login-container-adm">
                <div className="login-box">
                    <div className="card-body">
                        <div className='form-container-adm'>
                            <h2>Administrador AN</h2>
                            <Relogio blockedTime={blockedTime} classList='clock-adm'/>
                            <div className='row input-group-adm'>
                                <input type='email' id='email' className="campo-adm" onChange={focusEmail} disabled={isDisabled}/>
                                <label id='tag-email'>Email</label>
                            </div>
                            <div className='row input-group-adm'>
                                <input type={showPassword ? "text" : "password"} id='senha' className="campo-adm" onChange={focusSenha} disabled={isDisabled}/>
                                <label id='tag-senha'>Senha</label>
	    			<div className="show-hide-login">
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                    aria-label={
                                        showPassword
                                            ? "Ocultar senha"
                                            : "Mostrar senha"
                                    }
                                >
                                    {showPassword ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon />
                                    )}
                                </IconButton>
                            </div>
                            </div>
                            
                            <button onClick={()=>{sign(); removeInvalidBorder()}} className="btn-entrar" disabled={isDisabled}>Entrar</button>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default AdmLogin;
