import React from "react";
import { useState, useEffect } from "react";
import { postAxios } from "../../../hooks/useAxios";
import ItemMenuSsj from "../../custom/ssj/ItemMenuSsj";
import ItemMenuSsjAN from "../../custom/ssj/ItemMenuSsjAN";
import ItemMenuBi from "../../custom/bi/ItemMenuBi";
import ItemMenuPaymentPortal from "../../custom/payment-portal/ItemMenuPaymentPortal";
import ItemMenuManageUsers from "../../custom/manage-users/ItemMenuManageUsers";
import ItemMenuHolding from "../../custom/holding/ItemMenuHolding";
import ItemMenuManageSsj from "../../custom/manage-ssj/ItemMenuManageSsj";
import ItemMenuAnalysis from "../../custom/ssj-analysis/ItemMenuAnalysis";
import ItemMenuTranscription from "../../custom/transcription/ItemMenuTranscription";

import { Link } from "react-router-dom";

function AppMenu() {
    const [user, setUser] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [hasSsj, setHasSsj] = useState(false);
    const [hasBi, setHasBi] = useState(false);
    const [hasPaymentPortal, setHasPaymentPortal] = useState(false);
    const [hasManageSsj, setHasManageSsj] = useState(false);
    const [hasHolding, setHasHolding] = useState(false);
    const [hasAnalysis, setHasAnalysis] = useState(false);
    const [image, setImage] = useState("user.jpeg");

    useEffect(() => {
        const getUser = async () => {
            postAxios("/api/components/clients", null, null, {
                withCredentials: true,
            })
                .then((resp) => {
                    setUser(resp.data.user);
                    // setImage(resp.data.profilePic)
                    resp.data.user.functions &&
                        resp.data.user.functions.map((resp) => {
                            if (resp === "SSJ-Cliente") {
                                setHasSsj(true);
                            } else if (resp === "BI") {
                                setHasBi(true);
                            } else if (resp === "Portal-Pagamentos") {
                                setHasPaymentPortal(true);
                            } else if (resp === "SSJ-ADM") {
                                setHasManageSsj(true);
                            } else if (resp === "Holding") {
                                setHasHolding(true);
                            } else if (resp === "Analysis") {
                                setHasAnalysis(true);
                            }
                        });
                    resp.data.user.nivel === "ADM"
                        ? setIsAdmin(true)
                        : setIsAdmin(false);
                })
                .catch((error) => {
                    window.location.href = "/login";
                    console.error("Erro na solicitação POST:", error);
                });
        };

        getUser();
    }, [
        hasBi,
        hasHolding,
        hasManageSsj,
        hasPaymentPortal,
        hasSsj,
        hasAnalysis,
        isAdmin,
    ]);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* <!-- Brand Logo --> */}
            <Link to="/" className="brand-link">
                <img
                    src="/dist/img/pngBranco.png"
                    alt="AdminLTE Logo"
                    className="brand-image img-circle elevation-3"
                    style={{ opacity: ".8" }}
                />
                <span className="brand-text font-weight-light">Portal AN</span>
            </Link>

            {/* <!-- Sidebar --> */}
            <div className="sidebar">
                {/* <!-- Sidebar user (optional) --> */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image" id="foto-perfil">
                        <img
                            src={`/dist/img/${image}`}
                            className="img-circle elevation-2"
                            alt="User Image"
                        />
                    </div>
                    <div className="info" id="nome-perfil">
                        <Link className="d-block">
                            {user ? user.name : null}
                        </Link>
                    </div>
                </div>
                {/* <!-- Sidebar Menu --> */}
                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                <i className="nav-icon fas fa-th"></i>
                                <p>Início</p>
                            </Link>
                        </li>

                        {/* {CurrentView} */}
                        {hasSsj && !hasManageSsj ? (
                            <ItemMenuSsj show={hasSsj} />
                        ) : (
                            <></>
                        )}
                        {hasManageSsj ? (
                            <ItemMenuSsjAN show={hasManageSsj} />
                        ) : (
                            <></>
                        )}
                        {hasAnalysis ? (
                            <ItemMenuAnalysis show={hasAnalysis} />
                        ) : (
                            <></>
                        )}
                        {hasAnalysis ? (
                            <ItemMenuTranscription show={hasAnalysis} />
                        ) : (
                            <></>
                        )}
                        {hasBi && user.nivel === "ADM" ? (
                            <ItemMenuBi show={hasBi} />
                        ) : (
                            <></>
                        )}
                        {hasPaymentPortal ? (
                            <ItemMenuPaymentPortal show={hasPaymentPortal} />
                        ) : (
                            <></>
                        )}
                        {isAdmin ? (
                            <ItemMenuManageUsers show={isAdmin} />
                        ) : (
                            <></>
                        )}
                        {hasManageSsj ? (
                            <ItemMenuManageSsj show={hasManageSsj} />
                        ) : (
                            <></>
                        )}
                        {hasHolding ? (
                            <ItemMenuHolding show={hasHolding} />
                        ) : (
                            <></>
                        )}
                    </ul>
                </nav>

                {/* <!-- /.sidebar-menu --> */}
            </div>
            {/* <!-- /.sidebar --> */}
        </aside>
    );
}

export default AppMenu;
