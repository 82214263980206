import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import { postAxios } from "../../../hooks/useAxios";
import api from "../../../services/axios";
import React, { useState, useEffect } from "react";
import ViewService from "./ViewService";
import CardCancelService from "./CardCancelService";

function ViewManageSsj() {
    const [services, setServices] = useState(false);

    // pega o serviço especifico para visualizacao
    const [selectedService, setSelectedService] = useState({
        id: null,
        cliente: "",
        status: "",
        nome: "",
        email: "",
        tipo: null,
        departamento: "",
        natureza: "",
        pedido: "",
        comentario: "",
        prioridade: "",
        advogado: "",
        id_servico: "",
        pasta: "",
        status: "",
        client_id: 0,
        confirm: false,
    });

    useEffect(() => {
        console.log("Requisitado");
        api.post("/api/get/ssj", null, { withCredentials: true })
            .then((res) => {
                console.log("teeeeeeesteeeeee");
                console.log(res.data);
                setServices(res.data.services);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.responsestatus === 401) {
                        window.location.href = "/login";
                    } else {
                        alert("Algo de errado aconteceu!");
                    }
                } else {
                    alert(error.message);
                }
            });
    }, []);

    // const getServices = useCallback(async () => {
    //     postAxios('')
    //     .then((resp) => {
    //         console.log(resp);
    //         if(resp.status === 200){
    //             setServices(resp.data)
    //         }
    //         else {
    //             // window.location.href = "http://localhost:3000/bad/request";
    //             console.log('caiu no else');
    //         }
    //     })
    //     .catch((error) => {
    //         // window.location.href = "http://localhost:3000/bad/request";
    //         console.error('Erro na solicitação POST:', error);
    //     })
    // }, []);

    // useEffect(() => {
    //     getServices();
    // }, [getServices]);

    async function selectService(id) {
        const selected = services.find((service) => service.id === id);
        setSelectedService(selected);
    }

    // * Funcoes para criacao do modal de visualização de serviço
    const [showModalView, setShowModalView] = useState(false);

    const handleCloseModalView = () => {
        setShowModalView(false);
    };

    const handleShowModalView = () => {
        setShowModalView(true);
    };

    // * Funcoes para criacao do card de confirmacao de exclusao
    const [showCardCancel, setShowCardCancel] = useState(false);

    const handleCloseCardCancel = () => {
        setShowCardCancel(false);
    };

    const handleShowCardCancel = () => {
        setShowCardCancel(true);
    };

    // // * Spinner
    if (!services) {
        return;
        <>
            <div className="card-body">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </>;
    }

    return (
        <>
            <AppHeader />
            <AppMenu />
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Editor de serviço</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/">Início</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Editor de serviço
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            Tabela de serviços solicitados
                                        </h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <table
                                            id="example1"
                                            className="table table-bordered table-striped dataTable dtr-inline"
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="coluna-visualizar2">
                                                        Ações/Status
                                                    </th>
                                                    <th>Nº da pasta</th>
                                                    <th>Cliente</th>
                                                    <th>Solicitante</th>
                                                    <th>Tipo</th>
                                                    <th>Descrição</th>
                                                </tr>
                                            </thead>
                                            <tbody id="tabela-corpo">
                                                {services &&
                                                    services.map((resp) => (
                                                        <tr>
                                                            {resp.status ===
                                                            "concluido" ? (
                                                                <td>
                                                                    <h6 className="center-element">
                                                                        <i
                                                                            className="fa fa-check green"
                                                                            style={{
                                                                                marginRight: 5,
                                                                            }}
                                                                        ></i>
                                                                        Concluído
                                                                    </h6>
                                                                </td>
                                                            ) : resp.status ===
                                                              "cancelado" ? (
                                                                <td>
                                                                    <h6 className="center-element">
                                                                        <i
                                                                            className="fa fa-ban red"
                                                                            style={{
                                                                                marginRight: 5,
                                                                            }}
                                                                        ></i>
                                                                        Cancelado
                                                                    </h6>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    classList="action-column"
                                                                    className="center-element coluna-visualizar"
                                                                >
                                                                    <button
                                                                        className="btn btn-gray eye-animation"
                                                                        style={{
                                                                            marginRight: 5,
                                                                        }}
                                                                        onClick={() => {
                                                                            selectService(
                                                                                resp.id
                                                                            );
                                                                            handleShowModalView();
                                                                        }}
                                                                    >
                                                                        <i class="fa fa-eye icon-white"></i>
                                                                    </button>
                                                                    <a
                                                                        href={`/edit/service/${resp.id}`}
                                                                    >
                                                                        <button className="btn btn-primary pen-animation">
                                                                            <i class="fa fa-pen icon-white"></i>
                                                                        </button>
                                                                    </a>
                                                                    <button
                                                                        className="btn btn-danger trash-animation"
                                                                        style={{
                                                                            marginLeft: 5,
                                                                        }}
                                                                        onClick={() => {
                                                                            selectService(
                                                                                resp.id
                                                                            );
                                                                            handleShowCardCancel();
                                                                        }}
                                                                    >
                                                                        <i class="fa fa-ban"></i>
                                                                    </button>
                                                                </td>
                                                            )}
                                                            <td>
                                                                {resp.pasta}
                                                            </td>
                                                            <td>
                                                                {resp.cliente}
                                                            </td>
                                                            <td>{resp.nome}</td>
                                                            <td>{resp.tipo}</td>
                                                            <td>
                                                                {resp.pedido}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        <ViewService
                                            showModal={showModalView}
                                            handleCloseModal={
                                                handleCloseModalView
                                            }
                                            service={selectedService}
                                        />
                                        <CardCancelService
                                            showCard={showCardCancel}
                                            handleCloseCard={
                                                handleCloseCardCancel
                                            }
                                            message={
                                                "Essa ação irá cancelar este serviço. Tem certeza que deseja fazer isso?"
                                            }
                                            elementId={selectedService.id}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            <AppFooter />
            <AppSetting />
        </>
    );
}

export default ViewManageSsj;
