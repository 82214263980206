import { useState } from 'react';


function FeedbackSuccess() {


    return(
    <div className='feedback-total'>
    <p style={{textAlign: 'center'}} className='img-feedback'><img src="/dist/img/logoAN-feedback-white.png" alt="logo"/></p>
    <div className='feedback-message'>
        <div id="info-div" style={{textAlign: 'center'}}>
            <h2>Seu feedback foi enviado com sucesso!</h2>
            <p>Muito obrigado por nos dar sua avaliação, adoramos sua participação</p>
        </div>

    </div>
    </div>
    );
}

export default FeedbackSuccess;