import { useState } from 'react';
import FeedbackModal from './FeedbackModal';

function FeedbackPage() {


    return(
    <div className='feedback-container'>
        <FeedbackModal/>
    </div>
    );
}

export default FeedbackPage;