import AppHeader from '../../../basic/app-header/AppHeader';
import AdmMenu from '../adm-menu/AdmMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import { useState, useEffect } from 'react';
import api from '../../../../services/axios';
import ViewAdminUser from './ViewAdminUser';
import CardDeleteAdminUser from './CardDeleteAdminUser';

function ViewAdminUsers() {
    // variavel relativa aos usuarios administradores de cada cliente
    const [adminUsers, setAdminUsers] = useState(null);
    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        const getUsers = async () => {
          try {
            const response = await api.post('/api/get/adm');
            setAdminUsers(response.data.resp);
            console.log(response)
          } catch (error) {
            console.error(error);
            window.location.href = "/bad/request";
          }
        };
    
        getUsers();
        
    }, []);

    async function searchUser(id) {
        let left = 0;
        let right = adminUsers.length - 1;

        while(left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midValue = adminUsers[mid].id;

            if(midValue === id) {
                setSelectedUser(adminUsers[mid]);
                break;
            }
            else if(midValue < id) {
                left = mid + 1;
            }
            else {
                right = mid - 1;
            }
        }
    }

    // * Funcoes para criacao do modal de visualização de serviço
    const [showModalView, setShowModalView] = useState(false);
    
    const handleCloseModalView = () => {
        setShowModalView(false);
    };
    
      const handleShowModalView = () => {
        setShowModalView(true);
    };

    // * Funcoes para criacao do card de confirmacao de exclusao
    const [showCardDelete, setShowCardDelete] = useState(false);
    
    const handleCloseCardDelete = () => {
        setShowCardDelete(false);
    };
    
      const handleShowMCardDelete = () => {
        setShowCardDelete(true);
    };

    if(adminUsers === null) {
        return(
            <>
            <AppHeader/>
            <AdmMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter/>
            <AppSetting/>
            </>
        ); 
    }

    return(
        <>
        <AppHeader/>
        <AdmMenu/>
        <div className="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header" style={{marginBottom: -5}}>
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6"/>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/adm">Portal Administrador</a></li>
                                <li class="breadcrumb-item active">Administradores - Cliente</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- Main content --> */}
            <section class="content">
            <div class="container-fluid">
                <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" style={{fontWeight: 600}}>Usuários Administradores do Cliente</h3>
                    </div>
                    <div class="card-header">
                        <a href="/adm/create/admin-user"><button class="btn btn-sm btn-success">Novo administrador</button></a>
                    </div>
                    {/* <!-- /.card-header --> */}
                    <div class="card-body">
                        <table id="example1" class="table table-bordered table-striped">
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Cliente (Empresa)</th>
                            <th className='coluna-visualizar2'>Ações</th>
                        </tr>
                        </thead>
                        <tbody id="tabela-corpo">
                            {
                                adminUsers && adminUsers.map(resp=> (
                                    <tr>
                                        <td>{resp.name}</td>
                                        <td>{resp.email}</td>
                                        <td>{resp.cliente}</td>
                                        <td classList="action-column" className='center-element coluna-visualizar'>
                                            <button className='btn btn-gray eye-animation' style={{marginRight: 5}} onClick={()=>{searchUser(resp.id); handleShowModalView()}}><i class='fa fa-eye icon-white' ></i></button>
                                            <a href={`/adm/edit/admin-user/${resp.id}`}><button className='btn btn-primary pen-animation'><i class='fa fa-pen icon-white'></i></button></a>
                                            <button className='btn btn-danger trash-animation' style={{marginLeft: 5}} onClick={()=>{searchUser(resp.id); handleShowMCardDelete()}}><i class='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        </table>
                        <ViewAdminUser
                            showModal={showModalView}
                            handleCloseModal={handleCloseModalView}
                            user={selectedUser}
                        />
                        <CardDeleteAdminUser
                            showCard={showCardDelete}
                            handleCloseCard={handleCloseCardDelete}
                            message={'Essa ação irá excluir os dados do usuário administrador. Tem certeza de que deseja fazer isso?'}
                            elementId={selectedUser.id}
                        />
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </div>
        <AppFooter/>
        <AppSetting/>
        </>
    )

}

export default ViewAdminUsers;
