import AppHeader from "../../../basic/app-header/AppHeader";
import AdmMenu from "../adm-menu/AdmMenu";
import AppFooter from "../../../basic/app-footer/AppFooter";
import AppSetting from "../../../basic/app-setting/AppSetting";
import { useState, useEffect } from "react";
import {
    TextField,
    IconButton,
    Container,
    Box,
    InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../../../services/axios";

function SearchScreenRec() {
    const [searchTerm, setSearchTerm] = useState("");
    const [videoUrl, setVideoUrl] = useState("");

    const [auth, setAuth] = useState(false);

    useEffect(() => {
        const getUser = async () => {
            api.post("/api/load/clients", null, null, {
                withCredentials: true,
            })
                .then((resp) => {
                    console.log(resp);
                    setAuth(resp.data.authenticate);
                })
                .catch((error) => {
                    window.location.href = "/adm/login";
                    console.error("Erro na solicitação", error);
                });
        };
        getUser();
    }, []);

    if (auth === null || !auth) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    const handleSearch = async () => {
        if (searchTerm) {
            try {
                const response = await api.get(
                    `https://adm.almeidaenogueira.com.br/api/get/prints/${searchTerm}`,
                    {
                        responseType: "blob", // Importante para receber o vídeo como blob
                    }
                );
                console.log(response)
                const url = URL.createObjectURL(response.data);
                setVideoUrl(url);
            } catch (error) {
                console.error("Error fetching video:", error);
            }
        }
    };

    // * Conteúdo da página
    return (
        <>
            <AppHeader />
            <AdmMenu />
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section class="content-header" style={{ marginBottom: -5 }}>
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6" />
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item">
                                        <a href="/adm">Portal Administrador</a>
                                    </li>
                                    <li class="breadcrumb-item active">
                                        Clientes
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- Main content --> */}
                <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header bg-gray center-element">
                                        <h3
                                            class="card-title"
                                            style={{ fontWeight: 600 }}
                                        >
                                            Procure a gravação de tela
                                        </h3>
                                    </div>
                                    {/* <!-- /.card-header --> */}
                                    <div class="card-body">
                                        <Container maxWidth="sm">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                flexDirection="column"
                                                mt={4}
                                            >
                                                <TextField
                                                    label="Inserir número do serviço (7 dígitos)"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e) => {
                                                        const formattedValue = `video-Serv-${e.target.value}.webm`;
                                                        setSearchTerm(formattedValue);
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleSearch();
                                                        }
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={
                                                                        handleSearch
                                                                    }
                                                                >
                                                                    <SearchIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{ mb: 2 }}
                                                />
                                            </Box>
                                            {videoUrl && (
                                                <Box mt={4}>
                                                    <video
                                                        width="100%"
                                                        height="auto"
                                                        controls
                                                    >
                                                        <source
                                                            src={videoUrl}
                                                            type="video/mp4"
                                                        />
                                                        Seu navegador não
                                                        suporta a tag de vídeo.
                                                    </video>
                                                </Box>
                                            )}
                                        </Container>
                                    </div>
                                    {/* <!-- /.card-body --> */}
                                </div>
                                {/* <!-- /.card --> */}
                            </div>
                            {/* <!-- /.col --> */}
                        </div>
                        {/* <!-- /.row --> */}
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- /.content --> */}
            </div>
            <AppFooter />
            <AppSetting />
        </>
    );
}

export default SearchScreenRec;

