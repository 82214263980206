import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import React, { useState, useEffect, useCallback } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
import { postAxios } from "../../../hooks/useAxios";
import api from "../../../services/axios";

function EditManageSsj() {
    // id do servico, obtido pela url
    const { id } = useParams();
    const [service, setService] = useState(false);
    const [tipos, setTipos] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [participantes, setParticipantes] = useState([]);
    // const [fileList, setFileList] = useState(service.files);
    const maxChars = 500;

    // const getService = useCallback(async () => {
    //     postAxios('')
    //     .then((resp) => {
    //         console.log(resp);
    //         if(resp.status === 200){
    //             setService(resp.data)
    //         }
    //         else {
    //             // window.location.href = "http://localhost:3000/bad/request";
    //             console.log('caiu no else');
    //         }
    //     })
    //     .catch((error) => {
    //         // window.location.href = "http://localhost:3000/bad/request";
    //         console.error('Erro na solicitação POST:', error);
    //     })
    // }, []);

    // useEffect(() => {
    //     getService();
    // }, [getService]);

    useEffect(() => {
        api.post(`/api/get/one/ssj/${id}`, null, { withCredentials: true })
            .then((resp) => {
                console.log("<---------->");
                console.log(resp.data);
                setService(resp.data.ssj);
                setTipos(resp.data.tipos);
                setDepartamentos(resp.data.departamentos);
                setParticipantes(resp.data.participantes);
            })
            .catch((error) => {
                // window.location.href = "/bad/request";
                if (error.response) {
                    if (error.response.status === 401) {
                        window.location.href = "/login";
                    } else {
                        alert("Algo de errado aconteceu!");
                    }
                } else {
                    alert(error.message);
                }
            });
    }, []);

    if (service === false) {
        return (
            <>
                <div className="content-wrapper">
                    <div className="card-body">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100vh",
                            }}
                        >
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const form = new FormData();
    /*fileList.forEach((file, index) => {
        form.append('files', file);
    });*/
    /*console.log('-------------------')
    for (const pair of form.entries()) {
      console.log(pair[0], pair[1]);
    }*/

    // * Funcoes auxiliares

    // insercao dos arquivos
    /*const handleFileChange = (files) => {
      const fileListArray = Array.from(files);
      // atualizar o estado mantendo o que ja havia nele e adicionando os novos arquivos
      setFileList(prevFileList => [...prevFileList, ...fileListArray]);
    };
    
    // inserir arquivos arrastando
    const handleFileDrop = (e) => {
      e.preventDefault();
      const selectedFile = e.dataTransfer.files;
      handleFileChange(selectedFile);
    };
    
    // deletar arquivo
    const handleRemoveFile = (index) => {
      const updatedFileList = [...fileList];
      updatedFileList.splice(index, 1);
      setFileList(updatedFileList);
    };
    
    // preview do nome do arquivo adicionado
    const handlePreviewClick = () => {
      // Ative o input de arquivo quando o usuário clica no espaço reservado
      const fileInput = document.getElementById('fileInput');
      if (fileInput) {
        fileInput.click();
      }
    };*/

    // funcoes para limitacao dos campos a 500 caracteres
    function allInputChange(event) {
        const { name, value } = event.target;
        setService((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function sendService() {
        const body = {
            pedido: service.pedido,
            email: service.email,
            natureza: service.natureza,
            nome: service.nome,
            departamento: service.departamento,
            comentario: service.comentario,
            tipo: service.tipo,
            cliente: service.cliente,
            advogado: service.advogado,
            prioridade: service.prioridade,
        };
        console.log("formmmmmmmmmmmm");
        console.log(body);
        api
            .post(`/api/confirm/service/${id}`, body, { withCredentials: true })
            .then((resp) => {
                window.location.href = "/manage-ssj";
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        window.location.href = "/login";
                    }
                    if (error.response.data) {
                        alert(error.response.data);
                    } else {
                        alert("Algo de errado aconteceu!");
                    }
                }
            });
    }

    return (
        <>
            <AppHeader />
            <AppMenu />
            <div class="content-wrapper">
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>Editor de serviço</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item">
                                        <a href="/">Início</a>
                                    </li>
                                    <li class="breadcrumb-item active">
                                        Editor de serviço
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="content">
                    <div class="container-fluid">
                        <div class="card card-default">
                            <div className="card-header center-element bg-gray">
                                <h3
                                    className="card-title"
                                    style={{ fontWeight: 600 }}
                                >
                                    Editar serviço {service.id}
                                </h3>
                            </div>
                            <div class="card-body">
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label class="required" for="nome">
                                            Nome do solicitante:
                                        </label>
                                        <input
                                            type="text"
                                            name="nome"
                                            onChange={allInputChange}
                                            id="nome"
                                            class="form-control"
                                            value={service.nome}
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <label class="required" for="email">
                                            Email do solicitante:
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            onChange={allInputChange}
                                            id="email"
                                            class="form-control"
                                            value={service.email}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-4">
                                        <label class="required" for="advogado">
                                            Advogado(a) responsável:
                                        </label>
                                        <select
                                            name="advogado"
                                            id="advogado"
                                            onChange={allInputChange}
                                            class="form-control"
                                        >
                                            <option>{service.advogado}</option>
                                            {participantes.map((resp) => (
                                                <option>
                                                    {resp.participante}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-3">
                                        <label class="required" for="cliente">
                                            Cliente:
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            onChange={allInputChange}
                                            class="form-control readonly"
                                            value={service.cliente}
                                        />
                                    </div>
                                    <div className="form-group col-5">
                                        <label
                                            class="required"
                                            for="prioridade"
                                        >
                                            Prioridade:
                                        </label>
                                        <select
                                            name="prioridade"
                                            id="prioridade"
                                            onChange={allInputChange}
                                            class="form-control"
                                            value={service.prioridade}
                                        >
                                            <option></option>
                                            <option>
                                                URGENTE - entrega em até 01 dia
                                            </option>
                                            <option>
                                                MÉDIA - entrega em 03 dias
                                            </option>
                                            <option>
                                                NORMAL - entrga em 05 dias
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-4">
                                        <label class="required" for="email">
                                            Tipo:
                                        </label>
                                        <select
                                            type="select"
                                            value={service.tipo}
                                            onChange={allInputChange}
                                            name="tipo"
                                            id="tipo"
                                            class="form-control"
                                        >
                                            <option></option>
                                            {tipos.map((resp) => (
                                                <option>{resp.tipo}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-4">
                                        <label class="required" for="email">
                                            Natureza:
                                        </label>
                                        <select
                                            type="select"
                                            value={service.natureza}
                                            onChange={allInputChange}
                                            name="natureza"
                                            id="natureza"
                                            class="form-control"
                                        >
                                            <option></option>
                                            <option>
                                                EMPRESARIAL (Quaisquer outros
                                                serviços que não seja natureza
                                                Trabalhista)
                                            </option>
                                            <option>
                                                TRABALHISTA (Serviços que
                                                envolvam contrato de trabalho,
                                                direitos ou deveres dos
                                                funcionários)
                                            </option>
                                        </select>
                                    </div>
                                    <div className="form-group col-4">
                                        <label class="required" for="email">
                                            Departamento:
                                        </label>
                                        <select
                                            type="select"
                                            name="departamento"
                                            value={service.departamento}
                                            onChange={allInputChange}
                                            id="departamento"
                                            class="form-control"
                                        >
                                            <option></option>
                                            {departamentos.map((resp) => (
                                                <option>
                                                    {resp.departamento}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-4">
                                        <i class="fa fa-comment"></i>
                                        <label style={{ marginLeft: 3 }}>
                                            Comentários:
                                        </label>
                                        <textarea
                                            id="comentario"
                                            onChange={allInputChange}
                                            class="form-control"
                                            name="comentario"
                                            rows="6"
                                            placeholder="Coloque aqui comentários adicionais..."
                                            value={service.comentario}
                                        ></textarea>
                                        <p className="caracter-message">
                                            Caracteres restantes: {maxChars}
                                        </p>
                                    </div>
                                    <div className="form-group col-4">
                                        <i class="fa fa-paperclip"></i>
                                        <label style={{ marginLeft: 3 }}>
                                            Pedido do Cliente:
                                        </label>
                                        <textarea
                                            id="pedido"
                                            onChange={allInputChange}
                                            class="form-control"
                                            name="pedido"
                                            rows="6"
                                            placeholder="Coloque aqui o pedido do cliente..."
                                            value={service.pedido}
                                        ></textarea>
                                        <p className="caracter-message">
                                            Caracteres restantes: {maxChars}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-12 center-element">
                                    <a href="/manage-ssj">
                                        <button
                                            type="button"
                                            class="cancel btn btn-gray"
                                        >
                                            Cancelar
                                        </button>
                                    </a>
                                    <button
                                        type="submit"
                                        class="enviar btn btn-gray"
                                        style={{ marginLeft: 5 }}
                                        onClick={sendService}
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter />
            <AppSetting />
        </>
    );
}

export default EditManageSsj;

/*
<div className='form-group col-4'>
                                <i class="fa fa-folder"></i>
                                <label htmlFor="profileImage" style={{marginLeft: 3}}>Anexar documento:</label>
                                <div className={`form-group file-input-preview ${fileList.length > 0 ? 'file' : 'image'}`} onDrop={handleFileDrop} onDragOver={(e) => e.preventDefault()}>
                                    <div className="square-placeholder" onClick={handlePreviewClick}>
                                        <div className="file-container">
                                        {fileList.length > 0 ? (
                                            <ul className='archive-list'>
                                            {fileList.map((file, index) => (
                                                <li className='archive-item file-item'>
                                                {file.name}
                                                <button
                                                    className=" remove-archive"
                                                    onClick={() => handleRemoveFile(index)}
                                                >
                                                    <ClearIcon />
                                                </button>
                                                </li>
                                            ))}
                                            </ul>
                                        ) : (
                                            <div className="archive-indicator">
                                              <CloudUploadIcon />
                                            </div>
                                        )}
                                        </div>
                                        <input
                                          id="fileInput"
                                          type="file"
                                          multiple
                                          onChange={(e) => handleFileChange(e.target.files)}
                                          style={{ display: 'none' }}
                                        />
                                    </div>
                                    <label htmlFor="fileInput" className='small-text-archive'>
                                        Clique ou arraste!
                                    </label>
                                </div>
                              </div>
*/
